import { useState, React, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { Form } from "react-bootstrap";
import styles from "./HistoricalClaims.module.scss";
import moment from "moment";
import numeral from "numeral";
export function histClaimCalcTI(historicalClaims, year) {
  let TI, TR, TP;
  if (!historicalClaims[year].totInc || historicalClaims[year].totInc === "") {
    TI = 0;
  } else {
    TI = parseInt(
      historicalClaims[year].totInc.replace("$", "").replace(",", "")
    );
  }
  if (!historicalClaims[year].totRes || historicalClaims[year].totRes === "") {
    TR = 0;
  } else {
    TR = parseInt(
      historicalClaims[year].totRes.replace("$", "").replace(",", "")
    );
  }
  TP = "$" + formatAmount(TI - TR);

  historicalClaims[year].totPaid = TP;
  return historicalClaims;
}

export function histClaimCalcTP(historicalClaims, year) {
  let TI, TP, TR;
  if (historicalClaims[year]) {
    if (historicalClaims[year].totInc) {
      TI = parseInt(
        historicalClaims[year].totInc.replace("$", "").replace(",", "")
      );
      TP = parseInt(
        historicalClaims[year].totPaid.replace("$", "").replace(",", "")
      );
      TR = "$" + formatAmount(TI - TP);
    }
    if (
      historicalClaims[year].totPaid === "" ||
      historicalClaims[year].totPaid === "-"
    ) {
      TR = "$0";
    }

    historicalClaims[year].totRes = TR;
  }
  return historicalClaims;
}

export function histClaimCalcTR(historicalClaims, year) {
  let TI, TR, TP;
  if (historicalClaims[year].totInc) {
    TI = parseInt(
      historicalClaims[year].totInc.replace("$", "").replace(",", "")
    );
    TR = parseInt(
      historicalClaims[year].totRes.replace("$", "").replace(",", "")
    );
    TP = "$" + formatAmount(TI - TR);
  }
  if (
    historicalClaims[year].totRes === "" ||
    historicalClaims[year].totRes === "-"
  ) {
    TP = "$0";
  }

  historicalClaims[year].totPaid = TP;
  return historicalClaims;
}

function formatAmount(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

function HistoricalClaims({ jsonDataDownload }) {
  const [historicalClaims, setHistoricalClaims] = useState({});
  const [historicalClaimsCheck, setHistoricalClaimsCheck] = useState({});
  const [historicalClaimsErrorStore, setHistoricalClaimsErrorStore] = useState(
    {}
  );

  useEffect(() => {
    console.log("jsonDataDownload", jsonDataDownload);
    let preparedJsonBody = jsonDataDownload.reduce((acc, currentValue) => {
      const year = moment(currentValue?.eff_date).year();
      // if (acc[year]) {
      //   acc[year].total_recovered += currentValue.total_recovered || 0;
      //   acc[year].total_reserve += currentValue.total_reserve || 0;
      //   acc[year].total_payment += currentValue.total_payment || 0;
      //   acc[year].total_incurred += currentValue.total_incurred || 0;
      //   acc[year].no_claim += currentValue.no_claim || 0;
      // } else {
      acc[year] = currentValue;
      // acc[year].total_recovered = currentValue.total_recovered || 0;
      // acc[year].total_reserve = currentValue.total_reserve || 0;
      // acc[year].total_payment = currentValue.total_payment || 0;
      // acc[year].total_incurred = currentValue.total_incurred || 0;
      // acc[year].no_claim = currentValue.no_claim || 0;
      // }

      return acc;
    }, {});
    setHistoricalClaims(preparedJsonBody);
  }, []);

  function clearErrHistoricalClaims(e, key) {
    let year = e.target.id.split("-")[1];
    let _historicalClaimsErrorStore = JSON.parse(
      JSON.stringify(historicalClaimsErrorStore)
    );

    if (_historicalClaimsErrorStore[year]) {
      _historicalClaimsErrorStore[year][key] = false;
      setHistoricalClaimsErrorStore(_historicalClaimsErrorStore);
    }
  }

  function handleChange(e, key) {
    let year = e.target.id.split("-")[1];
    let _historicalClaims = JSON.parse(JSON.stringify(historicalClaims));

    if (!_historicalClaims[year]) {
      _historicalClaims[year] = {};
    }
    _historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      _historicalClaims = histClaimCalcTI(_historicalClaims, year);
    }
    if (key === "totPaid") {
      _historicalClaims = histClaimCalcTP(_historicalClaims, year);
    }
    if (key === "totRes") {
      _historicalClaims = histClaimCalcTR(_historicalClaims, year);
    }
    setHistoricalClaims(_historicalClaims);
  }

  function HistoricalClaimTextBlock(props) {
    let name = props.name;
    let label = props.label;
    let absYear = props.absYear;
    let historyLimitYear = props.historyLimitYear;
    let rowId = props.rowId;

    return (
      <Form.Group className="form-group">
        <label
          htmlFor={`${name}-${absYear}`}
          title={label}
          className="font-family-montserrat-semi-bold"
        >
          {label}
        </label>
        <input
          autoComplete="off"
          id={`${name}-${absYear}`}
          type="text"
          name="carText"
          onBlur={(e) => handleChange(e, name)}
          style={{
            backgroundColor:
              historyLimitYear > rowId || historicalClaimsCheck[absYear]
                ? "#dddddd"
                : "",
            borderColor: historicalClaimsErrorStore?.[absYear]?.[name]
              ? "red"
              : "",
          }}
          disabled={historyLimitYear > rowId}
          onKeyUp={(e) => {
            clearErrHistoricalClaims(e, historicalClaimsErrorStore, name);
          }}
          defaultValue={
            historicalClaims?.[absYear]?.[name]
              ? historicalClaims[absYear][name]
              : ""
          }
        />
      </Form.Group>
    );
  }

  function HistoricalClaimNumberBlock(props) {
    let name = props.name;
    let label = props.label;
    let absYear = props.absYear;
    let label_title = props.label_title;
    let historyLimitYear = props.historyLimitYear;
    let rowId = props.rowId;

    return (
      <Form.Group className="form-group">
        <label
          htmlFor={`${name}-${absYear}`}
          title={label_title}
          className="font-family-montserrat-semi-bold"
        >
          {label}
        </label>
        <NumericFormat
          name={name}
          id={`${name}-${absYear}`}
          autoComplete="off"
          onKeyUp={(e) => {
            clearErrHistoricalClaims(e, historicalClaimsErrorStore, name);
          }}
          thousandSeparator={true}
          onBlur={(e) => handleChange(e, name)}
          style={{
            backgroundColor:
              historyLimitYear > rowId || historicalClaimsCheck[absYear]
                ? "#dddddd"
                : "",
            borderColor: historicalClaimsErrorStore?.[absYear]?.[name]
              ? "red"
              : "",
          }}
          disabled={historyLimitYear > rowId}
          prefix={name === "no_claim" ? "" : "$"}
          className="text-center"
          type="text"
          defaultValue={
            historicalClaims?.[absYear]?.[name]
              ? historicalClaims[absYear][name]
              : 0
          }
        />
      </Form.Group>
    );
  }

  function HistoricalLossRatio(props) {
    let name = props.name;
    let label = props.label;
    let absYear = props.absYear;
    let label_title = props.label_title;
    let historyLimitYear = props.historyLimitYear;
    let rowId = props.rowId;

    return (
      <Form.Group className="form-group">
        <label
          htmlFor={`${name}-${absYear}`}
          title={label_title}
          className="font-family-montserrat-semi-bold"
        >
          {label}
        </label>
        <NumericFormat
          name={name}
          id={`${name}-${absYear}`}
          autoComplete="off"
          decimalScale={2}
          disabled={true}
          suffix={"%"}
          className="text-center"
          type="text"
          defaultValue={
            historicalClaims?.[absYear]?.["annPre"] &&
            historicalClaims?.[absYear]?.["total_incurred"]
              ? (numeral(
                  historicalClaims?.[absYear]?.["total_incurred"]
                ).value() /
                  numeral(historicalClaims?.[absYear]?.["annPre"]).value()) *
                100
              : 0
          }
        />
      </Form.Group>
    );
  }

  function HistoricalLossRatio(props) {
    let name = props.name;
    let label = props.label;
    let absYear = props.absYear;
    let label_title = props.label_title;
    let historyLimitYear = props.historyLimitYear;
    let rowId = props.rowId;

    return (
      <Form.Group className="form-group">
        <label
          htmlFor={`${name}-${absYear}`}
          title={label_title}
          className="font-family-montserrat-semi-bold"
        >
          {label}
        </label>
        <NumericFormat
          name={name}
          id={`${name}-${absYear}`}
          autoComplete="off"
          decimalScale={2}
          disabled={true}
          suffix={"%"}
          className="text-center"
          type="text"
          defaultValue={
            historicalClaims?.[absYear]?.["annPre"] &&
            historicalClaims?.[absYear]?.["total_incurred"]
              ? (numeral(
                  historicalClaims?.[absYear]?.["total_incurred"]
                ).value() /
                  numeral(historicalClaims?.[absYear]?.["annPre"]).value()) *
                100
              : ""
          }
        />
      </Form.Group>
    );
  }

  function handleCheck(e) {
    let checkbox = e.target;
    let year = checkbox.id.split("-")[1];
    let checked = checkbox.checked;
    let _historicalClaimsCheck = JSON.parse(
      JSON.stringify(historicalClaimsCheck)
    );
    let _historicalClaimsErrorStore = JSON.parse(
      JSON.stringify(historicalClaimsErrorStore)
    );

    _historicalClaimsCheck[year] = checked;

    for (let key in _historicalClaimsErrorStore[year]) {
      _historicalClaimsErrorStore[year][key] = false;
    }

    setHistoricalClaimsCheck(_historicalClaimsCheck);
    setHistoricalClaimsErrorStore(_historicalClaimsErrorStore);
  }

  let Table = () => {
    let yearsInBusiness = 5;
    // let absYear;
    let rowId, rowIdDup, rowIdYear, rowIdDupYear;
    rowIdYear = new Date().getFullYear();
    // rowIdDupYear = new Date().getFullYear();
    let historyLimitYear = rowIdYear - yearsInBusiness;
    return (
      Object.keys(historicalClaims).length > 0 &&
      Object.entries(historicalClaims).map((key_year) => {
        return (
          <>
            <div className="d-flex row">
              <tr id={key_year[0]} key={key_year}>
                <td id="carrier" style={{ padding: "0px" }} className="col-2">
                  <HistoricalClaimTextBlock
                    name="insurer_name"
                    label="Carrier"
                    absYear={key_year[0]}
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
                <td
                  id="Annaulprem"
                  style={{ padding: "0px" }}
                  className="col-2"
                >
                  <HistoricalClaimNumberBlock
                    name="annPre"
                    label="Annual Premium"
                    absYear={key_year[0]}
                    label_title="Annual Premium"
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
                <td id="claims" style={{ padding: "0px" }} className="col-1">
                  <HistoricalClaimNumberBlock
                    name="no_claim"
                    label="# Claims"
                    absYear={key_year[0]}
                    label_title="Number of claims"
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
                <td id="totPaid" style={{ padding: "0px" }} className="col-2">
                  <HistoricalClaimNumberBlock
                    name="total_payment"
                    label="Total Paid"
                    absYear={key_year[0]}
                    label_title="Total Paid"
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
                <td id="totRes" style={{ padding: "0px" }} className="col-2">
                  <HistoricalClaimNumberBlock
                    name="total_reserve"
                    label="Total Reserves"
                    absYear={key_year[0]}
                    label_title="Total Reserves"
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
                <td
                  id="totalincurred"
                  style={{ padding: "0px" }}
                  className="col-2"
                >
                  <HistoricalClaimNumberBlock
                    name="total_incurred"
                    label="Total Incurred"
                    absYear={key_year[0]}
                    label_title="Total Incurred"
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
                <td style={{ padding: "0px" }} className="col-1">
                  <HistoricalLossRatio
                    name="Loss ratio"
                    label="Loss ratio"
                    absYear={key_year[0]}
                    label_title="loss ratio"
                    historyLimitYear={historyLimitYear}
                    rowId={rowId}
                  />
                </td>
              </tr>
            </div>
            <div className={`row ml-0 mr-0 -mt-2 pl-2 pb-2 ${styles.yearRow} `}>
              <tr>
                <b>
                  <label className="fs-6">{`${
                    key_year?.[0]?.split("-")?.[0]
                  }: ${historicalClaims?.[key_year[0]]?.["policy_no"]}`}</label>
                </b>
              </tr>
            </div>
          </>
        );
      })
    );
  };

  return (
    <div className={styles.historicalClaimsContainer}>
      <h4 className="mb-3 mt-3">
        <b className="font-family-montserrat-bold">
          Historical claims information
        </b>
      </h4>
      <div className="row container-fluid mt-3 mb-3 ml-0 mr-0">
        <table className={styles.claimTable}>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <Table />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HistoricalClaims;
